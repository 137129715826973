/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AuthType = 'firstlogin' | 'firstlogin-email' | 'reset-password';

export const AuthType = {
    Firstlogin: 'firstlogin' as AuthType,
    FirstloginEmail: 'firstlogin-email' as AuthType,
    ResetPassword: 'reset-password' as AuthType
};

