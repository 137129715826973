/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserSettingsGetBillingResponseBillingHistoryInner } from './userSettingsGetBillingResponseBillingHistoryInner';
import { UserSettingsStartPaymentBody } from './userSettingsStartPaymentBody';


/**
 * 
 */
export interface UserSettingsGetBillingResponse { 
    /**
     * 
     */
    billingHistory: Array<UserSettingsGetBillingResponseBillingHistoryInner>;
    details?: UserSettingsStartPaymentBody;
    /**
     * 
     */
    basePrice: number;
    /**
     * 
     */
    vat: number;
    /**
     * 
     */
    totalToPay: number;
    /**
     * 
     */
    externalWebshopUrl?: string;
    /**
     * 
     */
    licenseNotificationStatus?: UserSettingsGetBillingResponse.LicenseNotificationStatusEnum;
    /**
     * 
     */
    licenseType: UserSettingsGetBillingResponse.LicenseTypeEnum;
    /**
     * 
     */
    remainingDays?: number;
    /**
     * 
     */
    licenseFrom?: string;
    /**
     * 
     */
    licenseTo?: string;
    /**
     * 
     */
    licensePaid?: boolean;
    /**
     * 
     */
    licenseTrial?: boolean;
    /**
     * 
     */
    externalLicenseType?: string;
    /**
     * 
     */
    externalWebshopCode?: string;
    /**
     * 
     */
    licenseAccountType?: string;
}
export namespace UserSettingsGetBillingResponse {
    export type LicenseNotificationStatusEnum = 'low' | 'high';
    export const LicenseNotificationStatusEnum = {
        Low: 'low' as LicenseNotificationStatusEnum,
        High: 'high' as LicenseNotificationStatusEnum
    };
    export type LicenseTypeEnum = 'active' | 'trial' | 'expired';
    export const LicenseTypeEnum = {
        Active: 'active' as LicenseTypeEnum,
        Trial: 'trial' as LicenseTypeEnum,
        Expired: 'expired' as LicenseTypeEnum
    };
}


