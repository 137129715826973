/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type Language = 'du' | 'nl' | 'fr' | 'en';

export const Language = {
    Du: 'du' as Language,
    Nl: 'nl' as Language,
    Fr: 'fr' as Language,
    En: 'en' as Language
};

