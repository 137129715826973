/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UserSettingsCheckMolliePaymentStatus { 
    /**
     * 
     */
    paymentStatus: UserSettingsCheckMolliePaymentStatus.PaymentStatusEnum;
}
export namespace UserSettingsCheckMolliePaymentStatus {
    export type PaymentStatusEnum = 'transit' | 'open' | 'pending' | 'paid';
    export const PaymentStatusEnum = {
        Transit: 'transit' as PaymentStatusEnum,
        Open: 'open' as PaymentStatusEnum,
        Pending: 'pending' as PaymentStatusEnum,
        Paid: 'paid' as PaymentStatusEnum
    };
}


