/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoginResponseMfaSetup } from './loginResponseMfaSetup';


/**
 * 
 */
export interface LoginResponse { 
    /**
     * 
     */
    userId?: number;
    /**
     * 
     */
    result: LoginResponse.ResultEnum;
    /**
     * 
     */
    mergeStatus?: LoginResponse.MergeStatusEnum;
    mfaSetup?: LoginResponseMfaSetup;
}
export namespace LoginResponse {
    export type ResultEnum = 'prompt-mfa' | 'setup-mfa' | 'login' | 'merge-in-progress';
    export const ResultEnum = {
        PromptMfa: 'prompt-mfa' as ResultEnum,
        SetupMfa: 'setup-mfa' as ResultEnum,
        Login: 'login' as ResultEnum,
        MergeInProgress: 'merge-in-progress' as ResultEnum
    };
    export type MergeStatusEnum = 'done' | 'queued' | 'processing' | 'error';
    export const MergeStatusEnum = {
        Done: 'done' as MergeStatusEnum,
        Queued: 'queued' as MergeStatusEnum,
        Processing: 'processing' as MergeStatusEnum,
        Error: 'error' as MergeStatusEnum
    };
}


