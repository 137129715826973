/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersImportDataInnerTraining } from './usersImportDataInnerTraining';
import { UsersImportDataInnerCampus } from './usersImportDataInnerCampus';
import { UsersImportDataInnerStudentnumber } from './usersImportDataInnerStudentnumber';
import { UsersImportDataInnerFirstname } from './usersImportDataInnerFirstname';


export interface UsersImportDataInner { 
    /**
     * 
     */
    type?: UsersImportDataInner.TypeEnum;
    /**
     * 
     */
    userId?: number;
    /**
     * 
     */
    subscriptionId?: number;
    /**
     * 
     */
    row: number;
    studentnumber?: UsersImportDataInnerStudentnumber;
    firstname: UsersImportDataInnerFirstname;
    lastname: UsersImportDataInnerFirstname;
    email: UsersImportDataInnerFirstname;
    campus?: UsersImportDataInnerCampus;
    training?: UsersImportDataInnerTraining;
}
export namespace UsersImportDataInner {
    export type TypeEnum = 'error' | 'existing' | 'new' | 'existing-user' | 'not-found' | 'archive' | 'already-archived';
    export const TypeEnum = {
        Error: 'error' as TypeEnum,
        Existing: 'existing' as TypeEnum,
        New: 'new' as TypeEnum,
        ExistingUser: 'existing-user' as TypeEnum,
        NotFound: 'not-found' as TypeEnum,
        Archive: 'archive' as TypeEnum,
        AlreadyArchived: 'already-archived' as TypeEnum
    };
}


