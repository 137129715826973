/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UserInfoAssistantSubscription { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    organisationId: number;
    /**
     * 
     */
    organisationName: string;
    /**
     * 
     */
    trainingId: number;
    /**
     * 
     */
    trainingName: string;
    /**
     * 
     */
    studentnumber?: string;
    /**
     * 
     */
    coordinatingSupervisorId?: number;
    /**
     * 
     */
    coordinatingSupervisorName?: string;
    /**
     * 
     */
    licenseFrom?: string;
    /**
     * 
     */
    licenseTo?: string;
    /**
     * 
     */
    campusId?: string;
    /**
     * 
     */
    campusName?: string;
    /**
     * 
     */
    licenseAccountId?: number;
    /**
     * 
     */
    licenseAccountName?: string;
    /**
     * 
     */
    status: UserInfoAssistantSubscription.StatusEnum;
    /**
     * 
     */
    isDefault?: boolean;
    /**
     * 
     */
    isArchived?: boolean;
}
export namespace UserInfoAssistantSubscription {
    export type StatusEnum = 'active' | 'archived' | 'expired';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Expired: 'expired' as StatusEnum
    };
}


